import {
  UseMutationResult,
  useMutation,
  queryOptions,
  keepPreviousData,
  useQueryClient,
  infiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { CommonError } from 'shared/api/types';
import { handleRequestError } from 'shared/lib/axios';
import { queryClient } from 'shared/lib/react-query';
import { pathKeys } from 'shared/lib/react-router';

import { addNotification } from 'shared/model/notification';

import {
  assignableRecruitersGet,
  demandItemArchive,
  demandItemCreate,
  demandItemGet,
  demandItemUpdate,
  demandListGetByPosition,
  demandParentInfoGet,
  demandWidgetGet,
} from './requests';
import {
  DemandCreateRequest,
  DemandDTO,
  DemandList,
  DemandListByPositionRequest,
  DemandParentInfo,
  DemandUpdateRequest,
  DemandWidgetList,
  DemandWidgetRequest,
  RecruiterDTO,
} from './types';

export type Recruiter = Omit<RecruiterDTO, 'pictureUrl'> & { picture: string };

export const keys = {
  root: () => ['demand'] as const,
  list: () => [...keys.root(), 'list'] as const,
  parentInfo: () => [...keys.root(), 'parentInfo'] as const,
  listWithFilters: (filters: DemandListByPositionRequest) => [...keys.root(), 'list', filters] as const,
  item: (id: string) => [...keys.root(), 'item', id] as const,
  parentInfoByPosition: (id: string) => [...keys.parentInfo(), 'byPositionId', id] as const,
  create: () => [...keys.root(), 'create'] as const,
  update: (id: string) => [...keys.root(), 'update', id] as const,
  archive: (id: string) => [...keys.root(), 'archive', id] as const,
  widget: () => [...keys.root(), 'widget'] as const,
  widgetWithFilters: (filters: Omit<DemandWidgetRequest, 'page'>) => [...keys.root(), 'widget', filters] as const,
  recruiters: () => [...keys.root(), 'recruiters'] as const,
};

export const demandsService = {
  queryKey: (filters: DemandListByPositionRequest) => keys.listWithFilters(filters),

  getCache: (filters: DemandListByPositionRequest) =>
    queryClient.getQueryData<DemandList>(demandsService.queryKey(filters)),

  queryOptions: (filters: DemandListByPositionRequest, enabled = true) => {
    const demandsKey = demandsService.queryKey(filters);
    return queryOptions({
      queryKey: demandsKey,
      queryFn: async ({ signal }) => {
        const data = await demandListGetByPosition(filters, { signal });
        return data;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => demandsService.getCache(filters)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(demandsKey)?.dataUpdatedAt,
      placeholderData: keepPreviousData,
      enabled,
    });
  },
};

export const demandService = {
  queryKey: (id: string) => keys.item(id),

  getCache: (id: string) => queryClient.getQueryData<DemandDTO>(demandService.queryKey(id)),

  setCache: (data: DemandDTO) => queryClient.setQueryData(demandService.queryKey(data.id), data),

  removeCache: (id: string) => queryClient.removeQueries({ queryKey: demandService.queryKey(id) }),

  queryOptions: (id: string, enabled = true) => {
    const demandKey = demandService.queryKey(id);
    return queryOptions({
      queryKey: demandKey,
      queryFn: async ({ signal }) => {
        const data = await demandItemGet(id, { signal });
        return data;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => demandService.getCache(id)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(demandKey)?.dataUpdatedAt,
      enabled,
    });
  },

  prefetchQuery: async (id: string) => queryClient.prefetchQuery(demandService.queryOptions(id)),
};

export const demandParentInfoService = {
  queryKey: (positionId: string) => keys.parentInfoByPosition(positionId),

  getCache: (positionId: string) =>
    queryClient.getQueryData<DemandParentInfo>(demandParentInfoService.queryKey(positionId)),

  queryOptions: (positionId: string, enabled = true) => {
    const positionKey = demandParentInfoService.queryKey(positionId);
    return queryOptions({
      queryKey: positionKey,
      queryFn: async ({ signal }) => {
        const data = await demandParentInfoGet(positionId, { signal });
        return data;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => demandParentInfoService.getCache(positionId)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(positionKey)?.dataUpdatedAt,
      enabled,
    });
  },

  prefetchQuery: async (id: string) => queryClient.prefetchQuery(demandParentInfoService.queryOptions(id)),
};

export const useCreateDemandMutation = (
  accountId: string,
  projectId: string,
): UseMutationResult<DemandDTO, CommonError, DemandCreateRequest> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.create(),
    mutationFn: demandItemCreate,
    onSuccess: (data) => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: keys.widget() });
      addNotification({
        type: 'short',
        method: 'success',
        description: 'Demand:DemandPage.notification.successfullyCreated.description',
      });
      demandService.setCache(data);
      navigate(pathKeys.position.root({ accountId, projectId }));
    },
    onError: (error) => {
      let errorMessage: string;
      let type: 'short' | 'long' = 'long';

      switch (error.response?.data.code) {
        case 'PositionAlreadyHasActiveDemandException':
          errorMessage = 'Demand:DemandPage.notification.positionAlreadyHasActiveDemand.description';
          break;
        case 'DesirableDateOutOfRangeException':
          errorMessage = 'Demand:DemandPage.notification.desirableDateOutOfRange.description';
          break;
        case 'ItemNotFoundException':
          errorMessage = 'Demand:DemandCreatePage.notification.notFound.description';
          type = 'short';
          break;
        default:
          handleRequestError(error);
          return;
      }

      addNotification({
        type,
        method: 'error',
        description: errorMessage,
      });
    },
  });
};

export const useUpdateDemandMutation = (
  accountId: string,
  projectId: string,
  demandId: string,
): UseMutationResult<DemandDTO, CommonError, DemandUpdateRequest> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.update(demandId),
    mutationFn: demandItemUpdate,
    onSuccess: (data) => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: keys.widget() });

      addNotification({
        type: 'short',
        method: 'success',
        description: 'Demand:DemandPage.notification.successfullyUpdated.description',
      });
      demandService.setCache(data);
      navigate(pathKeys.position.root({ accountId, projectId }));
    },
    onError: (error) => {
      let errorMessage: string;

      switch (error.response?.data.code) {
        case 'PositionAlreadyHasActiveDemandException':
          errorMessage = 'Demand:DemandPage.notification.positionAlreadyHasActiveDemand.description';
          break;
        case 'DesirableDateOutOfRangeException':
          errorMessage = 'Demand:DemandPage.notification.desirableDateOutOfRange.description';
          break;
        default:
          handleRequestError(error);
          return;
      }
      addNotification({
        type: 'long',
        method: 'error',
        description: errorMessage,
      });
    },
  });
};

export const demandWidgetService = {
  queryKey: (filters: Omit<DemandWidgetRequest, 'page'>) => keys.widgetWithFilters(filters),

  getCache: (filters: Omit<DemandWidgetRequest, 'page'>) =>
    queryClient.getQueryData<InfiniteData<DemandWidgetList>>(demandWidgetService.queryKey(filters)),

  queryOptions: (filters: Omit<DemandWidgetRequest, 'page'>) => {
    const filtrationKey = demandWidgetService.queryKey(filters);
    return infiniteQueryOptions({
      queryKey: filtrationKey,
      queryFn: async ({ pageParam, signal }) => {
        const data = await demandWidgetGet({ ...filters, page: pageParam.page }, { signal });
        return data;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      initialPageParam: { page: 0 } as any,
      getNextPageParam: (lastPage) => {
        if (lastPage.totalPages <= lastPage.pageable.pageNumber + 1) {
          return null;
        }
        return {
          page: lastPage.pageable.pageNumber + 1,
        };
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => demandWidgetService.getCache(filters)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(filtrationKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (filters: Omit<DemandWidgetRequest, 'page'>) => {
    queryClient.prefetchQuery(demandWidgetService.queryOptions(filters));
  },
};

export const recruitersService = {
  queryKey: () => keys.recruiters(),

  getCache: () => queryClient.getQueryData<Recruiter[]>(recruitersService.queryKey()),

  queryOptions: (enabled = true) => {
    const recruitersKey = recruitersService.queryKey();
    return queryOptions({
      queryKey: recruitersKey,
      queryFn: async ({ signal }) => {
        const data = await assignableRecruitersGet({ signal });
        const sortedData = data
          ?.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName))
          .map(({ pictureUrl, ...user }) => ({ ...user, picture: pictureUrl }));
        return sortedData;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => recruitersService.getCache()!,
      initialDataUpdatedAt: () => queryClient.getQueryState(recruitersKey)?.dataUpdatedAt,
      enabled,
    });
  },
};

export const useArchiveDemandMutation = (
  accountId: string,
  projectId: string,
  demandId: string,
): UseMutationResult<void, CommonError, string> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.archive(demandId),
    mutationFn: demandItemArchive,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: keys.widget() });
      demandService.removeCache(demandId);
      addNotification({
        type: 'short',
        method: 'success',
        description: 'Demand:DemandPage.notification.successfullyArchived.description',
      });
      navigate(pathKeys.position.root({ accountId, projectId }));
    },
    onError: (error) => {
      handleRequestError(error);
    },
  });
};
