import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { CommonError } from 'shared/api/types';
import { deleteToken, returnToName, saveToken } from 'shared/lib/axios';
import { LocalStorageService } from 'shared/lib/localStorage';
import { pathKeys } from 'shared/lib/react-router';
import { addNotification } from 'shared/model/notification';

import { authStore } from '../model/store';

import { authenticatedUserGet, logIn, logInWithGoogle, logOut } from './requests';
import { AuthFormLoginRequest, AuthResponse, AuthUserDTO } from './types';

const keys = {
  root: () => ['auth'],
  currentUser: () => [...keys.root(), 'currentUser'] as const,
  loginUser: () => [...keys.root(), 'loginUser'] as const,
  logoutUser: () => [...keys.root(), 'logoutUser'] as const,
};

export const useAuthUserMutation = (): UseMutationResult<AuthUserDTO, unknown, void> => {
  const navigate = useNavigate();

  return useMutation({
    mutationKey: keys.currentUser(),
    mutationFn: authenticatedUserGet,
    onSuccess: (data) => {
      authStore.getState().updateAuthUser(data);

      const returnToList = LocalStorageService.getItem<Record<string, string>>(returnToName) ?? {};
      const returnTo = returnToList[data.id];

      if (returnTo) {
        delete returnToList[data.id];
      }
      navigate(returnTo ?? pathKeys.dashboard());
    },
  });
};

const logInHandler = async (tokens: AuthResponse): Promise<void> => {
  const { accessToken, refreshToken, refreshTokenExpiredAt } = tokens;

  saveToken(accessToken);

  authStore.getState().updateTokens({ token: accessToken, refreshToken, refreshTokenExpiredAt });
};

export function useLoginUserMutation(): UseMutationResult<AuthResponse, CommonError, AuthFormLoginRequest> {
  const { mutate: getAuthInfo } = useAuthUserMutation();

  return useMutation({
    mutationKey: keys.loginUser(),
    mutationFn: logIn,
    onSuccess: (tokens) => {
      logInHandler(tokens);
      getAuthInfo();
    },
    onError(error) {
      if (error.response?.data.code === 'UserBlockedException') {
        addNotification({
          type: 'short',
          method: 'warning',
          description: 'Auth:LogInForm.message.maximumAttempts.description',
        });
      }
    },
  });
}

export function useGoogleLoginUserMutation(): UseMutationResult<AuthResponse, unknown, string> {
  const { mutate: getAuthInfo } = useAuthUserMutation();

  return useMutation({
    mutationKey: keys.loginUser(),
    mutationFn: logInWithGoogle,
    onSuccess: (tokens) => {
      logInHandler(tokens);
      getAuthInfo();
    },
  });
}

export function useLogoutMutation(returnTo?: string): UseMutationResult<void, unknown, void> {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: keys.logoutUser(),
    mutationFn: logOut,
    onSettled: async () => {
      const authState = authStore.getState();

      const id = authState.authUser?.id;
      if (id && returnTo) {
        const previousReturnTo: Record<string, string> = LocalStorageService.getItem(returnToName) ?? {};
        LocalStorageService.setItem(returnToName, JSON.stringify({ ...previousReturnTo, [id]: returnTo }));
      }

      authState.reset();
      queryClient.cancelQueries();
      queryClient.clear();
      deleteToken();
      navigate(pathKeys.login());
    },
  });
}
