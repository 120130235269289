import React from 'react';

import { UserName } from 'features/UserCard';

import { Maybe } from 'shared/types';
import { Avatar } from 'shared/ui/Avatar';

import styles from './UserOverviewWidgetItem.module.scss';

interface IUserOverviewWidgetItemProps {
  picture: Maybe<string>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  workProfile: Maybe<string>;
  specialization: Maybe<string>;
  seniority: Maybe<string>;
  managerBamboo: Maybe<string>;
  id: string;
}

const UserOverviewWidgetItem: React.FC<IUserOverviewWidgetItemProps> = (props) => {
  const { picture, firstName, lastName, workProfile, specialization, seniority, id, managerBamboo } = props;

  return (
    <div className={styles.itemWrapper} data-testid="user-overview-item">
      <div className={styles.nameWrapper}>
        <div className={styles.avatar}>
          <Avatar size={24} picture={picture} text={`${firstName} ${lastName}`} />
        </div>
        <UserName name={`${firstName} ${lastName}`} id={id} />
      </div>
      <span className={styles.infoItem}>{seniority}</span>
      <span className={styles.infoItem}>{specialization}</span>
      <span className={styles.infoItem}>{workProfile}</span>
      <span className={styles.infoItem}>{managerBamboo}</span>
    </div>
  );
};

export default UserOverviewWidgetItem;
