import React, { useRef } from 'react';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { SortOrder } from 'antd/lib/table/interface';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useStore } from 'zustand';
import {
  DEFAULT_USER_OVERVIEW_SORTING,
  userOverviewService,
  userOverviewStore,
  type OrderDirection,
  type UserOverviewDTO,
} from 'entities/userOverview';

import { withSuspense } from 'shared/lib/react';
import { Loader } from 'shared/ui/Loader';
import { VirtualTable } from 'shared/ui/VirtualTable';

import UserTableLoader from './ui/UserTableLoader/UserTableLoader';
import { columns, PAGE_SIZE, THRESHOLD_VALUE } from './UserTable.const';

const UserTable: React.FC = () => {
  const loaderRef = useRef<InfiniteScroll>(null);

  const { filters, searchQuery, setOrder } = useStore(userOverviewStore);

  const {
    data: usersData,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useSuspenseInfiniteQuery(userOverviewService.queryOptions({ filtration: filters, size: PAGE_SIZE, searchQuery }));

  const parseSortOrder = (sortOrder: SortOrder): OrderDirection => {
    return sortOrder === 'ascend' ? 'ASC' : 'DESC';
  };

  const handleSortingChange = (fieldName?: React.Key | readonly React.Key[], order?: SortOrder): void => {
    if (!fieldName || !order) {
      setOrder(DEFAULT_USER_OVERVIEW_SORTING);
      return;
    }
    const parsedOrder = parseSortOrder(order);
    const orders = [{ fieldName: fieldName as string, orderDirection: parsedOrder }];
    if (fieldName === 'firstName') {
      orders.push({ fieldName: 'lastName', orderDirection: parsedOrder });
    }
    setOrder(orders);
  };

  const tableData = usersData.pages.reduce<UserOverviewDTO[]>((acc, page) => [...acc, ...page.content], []);

  const tableDataMapped = tableData?.map(({ id, firstName, lastName, picture, ...user }) => ({
    ...user,
    key: id,
    name: { id, firstName, lastName, picture },
  }));

  return (
    <VirtualTable
      columns={columns}
      dataSource={tableDataMapped}
      scroll={{ x: 'max-content' }}
      loadNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isNextPageLoading={isLoading}
      onSort={handleSortingChange}
      loaderRef={loaderRef}
      rowLoader={<UserTableLoader columnsLength={columns.length} />}
      threshold={THRESHOLD_VALUE}
    />
  );
};

export default withSuspense(UserTable, { fallback: <Loader /> });
