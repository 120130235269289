import { useCallback, useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { DEFAULT_LOGOUT_TIMEOUT } from '../const';
import { updateLogoutTime } from './updateLogoutTime';

type ReturnType = (enabled: boolean) => void;

export const useRequestListener = (): ReturnType => {
  const qClient = useQueryClient();
  const status = useRef(false);

  useEffect(() => {
    const oldMutationDefaults = qClient.getMutationDefaults([]);

    qClient.setMutationDefaults([], {
      ...oldMutationDefaults,
      onMutate: (vars) => {
        if (status.current) {
          const time = dayjs().add(DEFAULT_LOGOUT_TIMEOUT);
          updateLogoutTime(time);
        }
        oldMutationDefaults.onMutate?.(vars);
      },
    });

    return () => {
      qClient.setMutationDefaults([], oldMutationDefaults);
    };
  }, [qClient]);

  const changeStatus = useCallback((enabled: boolean) => {
    status.current = enabled;
  }, []);

  return changeStatus;
};
