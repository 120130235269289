import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableProps } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import ResizeObserver from 'rc-resize-observer';

import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import TableLoader from './ui/tableLoader';
import { IVirtualTableProps } from './VirtualTable.types';

import styles from './VirtualTable.module.scss';

function VirtualTable<T extends object = never>({
  columns,
  scroll,
  dataSource,
  loadNextPage,
  hasNextPage,
  isNextPageLoading,
  threshold = 0.9,
  onSort,
  loaderRef,
  rowLoader,
}: IVirtualTableProps<T>): React.ReactElement {
  const { t } = useTranslation();

  const [currentHeight, setCurrentHeight] = useState(0);
  const onChange: TableProps['onChange'] = (_, __, sorter, extra) => {
    if (extra.action === 'sort') {
      const currentSorter = sorter as SorterResult<T>;
      onSort(currentSorter?.columnKey, currentSorter.order);
    }
  };

  const loadMoreData = useMemo(
    () => (isNextPageLoading ? () => null : loadNextPage),
    [isNextPageLoading, loadNextPage],
  );

  const extendedColumns = useMemo((): ColumnType<T>[] => {
    return columns.map((column) => {
      return {
        ...column,
        title: t(column.title),
        sorter: true,
      };
    });
  }, [columns, t]);

  const tableSummary = useMemo(() => {
    if (rowLoader) {
      return hasNextPage ? rowLoader : null;
    }
    return hasNextPage ? <TableLoader columnsLength={extendedColumns.length} /> : null;
  }, [hasNextPage, extendedColumns]);

  useEffect(() => {
    const tableContent = (document.getElementById('tableId') as HTMLElement).offsetHeight;

    if (tableContent < currentHeight) {
      loadMoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHeight]);

  return (
    <ResizeObserver
      onResize={({ height }) => {
        setCurrentHeight(height);
      }}
    >
      <div className={styles.scrollableDiv} id="scrollableDiv">
        <InfiniteScroll
          ref={loaderRef}
          scrollableTarget="scrollableDiv"
          dataLength={dataSource.length}
          scrollThreshold={threshold}
          next={loadMoreData}
          hasMore={hasNextPage}
          loader={null}
        >
          <Table
            className={styles.virtualTable}
            id="tableId"
            virtual
            columns={extendedColumns}
            pagination={false}
            bordered
            scroll={scroll}
            dataSource={dataSource}
            showSorterTooltip={false}
            onChange={onChange}
            summary={() => tableSummary}
          />
        </InfiniteScroll>
      </div>
    </ResizeObserver>
  );
}

export default React.memo(VirtualTable);
