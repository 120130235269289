import { QueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { pathKeys } from '../react-router';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 3,
    },
    mutations: {
      throwOnError(error) {
        if (isAxiosError(error)) {
          const { pathname } = window.location;
          return (error.response?.status === 401 && pathname !== pathKeys.login()) || error.response?.status === 403;
        }
        return true;
      },
    },
  },
});
