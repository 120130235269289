import { Skeleton, Table } from 'antd';

const TableLoader = ({ columnsLength }: { columnsLength: number }): JSX.Element => {
  return (
    <Table.Summary.Row>
      {Array.from({ length: columnsLength }, (_, index) => (
        <Table.Summary.Cell index={index} key="tableLoader">
          <Skeleton paragraph={false} active title={{ width: '100%' }} />
        </Table.Summary.Cell>
      ))}
    </Table.Summary.Row>
  );
};

export default TableLoader;
