import React from 'react';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';

import { userOverviewService, type UserOverviewDTO, type UserOverviewFiltration } from 'entities/userOverview';

import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import { LinkButton } from 'shared/ui/LinkButton';
import { Loader } from 'shared/ui/Loader';
import { VirtualList } from 'shared/ui/VirtualList';

import UserOverViewWidgetColumnsHeader from './ui/UserOverViewColumnsHeader/UserOverViewColumnsHeader';
import UserOverviewWidgetItem from './ui/UserOverviewWidgetItem/UserOverviewWidgetItem';

import styles from './UserOverviewWidget.module.scss';

const PAGE_SIZE = 24;
const DEFAULT_FILTRATION: UserOverviewFiltration = {
  showOnlyNonBillable: false,
  availableUsers: true,
  filters: [],
  orders: [
    { fieldName: 'firstName', orderDirection: 'ASC' },
    { fieldName: 'lastName', orderDirection: 'ASC' },
  ],
};

const ITEM_SIZE = 32;

const UserOverviewWidget: React.FC = () => {
  const { t } = useTranslation();

  const {
    data: userListData,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useSuspenseInfiniteQuery(userOverviewService.queryOptions({ filtration: DEFAULT_FILTRATION, size: PAGE_SIZE }));

  const mappedData = userListData?.pages?.reduce<UserOverviewDTO[]>((acc, { content }) => [...acc, ...content], []);

  return (
    <div className={styles.rootWrapper}>
      <div className={styles.heading}>
        <h5 className={styles.headingText}>{t('UserOverview:UserOverviewWidget.heading')}</h5>
        <div>
          <LinkButton
            buttonProps={{ type: 'primary' }}
            linkProps={{ to: `${pathKeys.userOverview()}?availableUsers=true` }}
          >
            {t('UserOverview:UserOverviewWidget.toPanoramaButton.text')}
          </LinkButton>
        </div>
      </div>
      <div className={styles.listWrapper} data-testid="user-overview-widget">
        <div className={styles.itemContainer}>
          <UserOverViewWidgetColumnsHeader />
          <VirtualList
            data={mappedData}
            itemSize={ITEM_SIZE}
            hasNextPage={hasNextPage}
            loadNextPage={fetchNextPage}
            isNextPageLoading={isLoading}
            threshold={6}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderItem={({ data }) => <UserOverviewWidgetItem {...data} />}
          />
        </div>
      </div>
    </div>
  );
};

export default withSuspense(UserOverviewWidget, { fallback: <Loader /> });
