import { OverrideToken } from 'antd/es/theme/interface';

import variables from '../UIConfigProvider.module.scss';

export const Button: OverrideToken['Button'] = {
  colorPrimary: variables.clrPalettePrimary,
  colorPrimaryBorder: variables.clrPalettePrimary,
  colorPrimaryActive: variables.clrPaletteDarkPrimary,
  colorPrimaryHover: variables.clrPaletteLightPrimary,

  defaultBorderColor: variables.clrPaletteMiddleGray,
  defaultBg: variables.clrPaletteWhite,
  defaultHoverBorderColor: variables.clrPalettePrimary,
  defaultHoverColor: variables.clrPalettePrimary,

  textHoverBg: variables.clrPaletteExtraLightGray,

  colorLink: variables.clrPaletteBlack,

  paddingBlock: 8,
  paddingInline: 24,

  controlHeightLG: 40,
  controlHeightSM: 32,
  controlHeightXS: 24,

  borderRadius: 6,
  primaryShadow: 'none',
  colorTextDisabled: variables.clrPaletteMiddleGray,
  borderColorDisabled: variables.clrPaletteLightGray,
  colorBgContainerDisabled: variables.clrPaletteExtraLightGray,
  dangerColor: variables.clrPaletteWhite,
  colorError: variables.clrPaletteError,
};
