import React from 'react';

import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { demandWidgetService, DemandWidgetDTO } from 'entities/demand';

import { withSuspense } from 'shared/lib/react';
import { Loader } from 'shared/ui/Loader';
import { VirtualList } from 'shared/ui/VirtualList';

import DemandWidgetItem from './ui/DemandWidgetItem/DemandWidgetItem';

import styles from './DemandWidget.module.scss';

const PAGE_SIZE = 24;
const DEFAULT_SORTING = 'demandStatusOrder,demandDesirableDate,desc';

const DemandWidget: React.FC = () => {
  const { t } = useTranslation();

  const {
    data: demandWidgetData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useSuspenseInfiniteQuery(demandWidgetService.queryOptions({ size: PAGE_SIZE, sort: DEFAULT_SORTING }));

  const mappedData = demandWidgetData?.pages?.reduce<DemandWidgetDTO[]>((acc, { content }) => [...acc, ...content], []);

  return (
    <div className={styles.demandWidgetHolder}>
      <h5 className={styles.headerDemand}>{t('Demand:DemandWidgetList.heading')}</h5>
      <VirtualList
        data={mappedData}
        itemSize={32}
        loadNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isNextPageLoading={isFetchingNextPage}
        renderItem={({ data: demandData }) => <DemandWidgetItem demandData={demandData} />}
        threshold={6}
        layout="vertical"
      />
    </div>
  );
};

export default withSuspense(DemandWidget, { fallback: <Loader /> });
