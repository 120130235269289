import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './UserOverViewWidgetColumnsHeader.module.scss';

const UserOverViewWidgetColumnsHeader = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.columnHeadersWrapper}>
      <span className={styles.firstColumn}>{t('UserOverview:UserOverviewWidget.header.column.name.title')}</span>
      <span className={styles.columnHeader}>{t('UserOverview:UserOverviewWidget.header.column.seniority.title')}</span>
      <span className={styles.columnHeader}>
        {t('UserOverview:UserOverviewWidget.header.column.specialization.title')}
      </span>
      <span className={styles.columnHeader}>
        {t('UserOverview:UserOverviewWidget.header.column.workProfile.title')}
      </span>
      <span className={styles.columnHeader}>{t('UserOverview:UserOverviewWidget.header.column.manager.title')}</span>
    </div>
  );
};

export default UserOverViewWidgetColumnsHeader;
