import { Fragment, ReactNode, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Skeleton, Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import 'dayjs/locale/ru';

import { LanguageService } from 'features/changeLanguage';
import { isHasAccess } from 'features/validateAccess';

import { userService } from 'entities/user';

import { convertDayJsToString } from 'shared/lib/dayjs';
import { pathKeys } from 'shared/lib/react-router';
import tabStyles from 'shared/styles/ant-custom-components/tabs.module.scss';
import { Avatar } from 'shared/ui/Avatar';

import { ASSIGNMENTS_TAB_PERMISSIONS, PERMISSION_TAB_PERMISSIONS, PRIVATE_TAB_PERMISSIONS } from '../../permissions';
import { InvolvementTimelines } from '../InvolvementsTimelines/InvolvementTimelines';
import { PermissionsTab } from '../PermissionsTab/permissionsTab';

import styles from './CardContent.module.scss';

const PROFILE_CREATED_DATE_FORMAT = 'MMM DD, YYYY';

type CardContentProps = {
  userId: string;
};

export const CardContent: React.FC<CardContentProps> = ({ userId }) => {
  const { t } = useTranslation();

  const { data: user, isPending: isUserLoading } = useQuery(userService.queryOptions(userId));

  const fullName = `${user?.firstName} ${user?.lastName}`;

  const renderImage = (): ReactNode => {
    return (
      <div className={styles.image}>
        <Skeleton
          active
          avatar={{ size: 128 }}
          paragraph={false}
          title={false}
          loading={isUserLoading}
          className={styles.avatarSkeleton}
        >
          <Avatar picture={user?.picture} text={fullName} size={128} />
        </Skeleton>
      </div>
    );
  };

  const renderUserDataItem = (item: { name: string; value: string }): ReactNode => {
    return (
      <div className={styles.userDataItem}>
        <div className={styles.userDataItemName}>{item.name}</div>
        <Skeleton active loading={isUserLoading} title={false} paragraph={{ rows: 1 }} className={styles.valueSkeleton}>
          <div className={styles.userDataItemValue}>{item.value}</div>
        </Skeleton>
      </div>
    );
  };

  const renderHeading = (): JSX.Element => {
    const workProfile = user?.workProfile?.workProfile ?? '';
    return (
      <div className={styles.heading}>
        <Skeleton active loading={isUserLoading} title={false} paragraph={{ rows: 1 }} className={styles.valueSkeleton}>
          <h4 className={styles.fullName}>{fullName}</h4>
          <div className={styles.workProfile}>{workProfile}</div>
        </Skeleton>
      </div>
    );
  };

  const renderManager = (): ReactNode => {
    const item = {
      name: t('UserOverview:UserCard.view.manager'),
      value: user?.managerBamboo ? user.managerBamboo : t('UserOverview:UserCard.view.defaultValue'),
    };
    return renderUserDataItem(item);
  };

  const renderEmail = (): ReactNode => {
    const item = {
      name: t('UserOverview:UserCard.view.email'),
      value: user?.email ?? '',
    };
    return renderUserDataItem(item);
  };

  const renderLocation = (): ReactNode => {
    const item = {
      name: t('UserOverview:UserCard.view.location'),
      value: user?.location ? user.location.location : t('UserOverview:UserCard.view.defaultValue'),
    };
    return renderUserDataItem(item);
  };

  const renderRole = (): ReactNode => {
    const specialization = user?.specialization?.specialization ?? '';
    const seniority = user?.seniority?.seniority ?? '';
    const workProfile = user?.workProfile?.workProfile ?? '';
    const item = {
      name: t('UserOverview:UserCard.view.role'),
      value:
        seniority || specialization || workProfile
          ? `${seniority} ${specialization} ${workProfile}`
          : t('UserOverview:UserCard.view.defaultValue'),
    };
    return renderUserDataItem(item);
  };

  const renderHireData = (): ReactNode => {
    const value = user?.hireDate
      ? convertDayJsToString(
          dayjs(user.hireDate).locale(LanguageService.getCurrentLanguage()),
          PROFILE_CREATED_DATE_FORMAT,
        )
      : t('UserOverview:UserCard.view.defaultValue');

    const item = {
      name: t('UserOverview:UserCard.view.hireDate'),
      value: value[0].toUpperCase() + value.slice(1),
    };
    return renderUserDataItem(item);
  };

  const renderCVLink = (): ReactNode => {
    const item = {
      name: t('UserOverview:UserCard.view.CVLink'),
      value: user?.cvLink ? user.cvLink : t('UserOverview:UserCard.view.defaultValue'),
    };

    return (
      <div className={styles.userDataItem}>
        <div className={styles.userDataItemName}>{item.name}</div>
        <Skeleton active loading={isUserLoading} title={false} paragraph={{ rows: 1 }} className={styles.valueSkeleton}>
          {user?.cvLink ? (
            <Link to={`${pathKeys.externalWarning()}?link=${item.value}`} className={styles.cvLink} target="_blank">
              {item.value}
            </Link>
          ) : (
            <div className={styles.userDataItemValue}>{item.value}</div>
          )}
        </Skeleton>
      </div>
    );
  };

  const renderEmploymentStatus = (): ReactNode => {
    const item = {
      name: t('UserOverview:UserCard.view.employmentStatus'),
      value: user?.employmentType ? user.employmentType : t('UserOverview:UserCard.view.defaultValue'),
    };
    return renderUserDataItem(item);
  };

  const renderCountry = (): ReactNode => {
    const item = {
      name: t('UserOverview:UserCard.view.country'),
      value: user?.country?.country ? user.country.country : t('UserOverview:UserCard.view.defaultValue'),
    };
    return renderUserDataItem(item);
  };

  const headerTabs: TabsProps['items'] = useMemo(() => {
    const tabs: TabsProps['items'] = [
      {
        key: 'basic',
        label: t('UserOverview:UserCard.heading.tabs.basic'),
        children: (
          <Fragment>
            {renderManager()}
            {renderEmail()}
            {renderRole()}
          </Fragment>
        ),
      },
      {
        key: 'additional',
        label: t('UserOverview:UserCard.heading.tabs.additional'),
        children: (
          <Fragment>
            {renderLocation()}
            {renderHireData()}
            {renderCountry()}
          </Fragment>
        ),
      },
    ];

    if (isHasAccess(PRIVATE_TAB_PERMISSIONS)) {
      tabs.push({
        key: 'private',
        label: t('UserOverview:UserCard.heading.tabs.private'),
        children: (
          <Fragment>
            {renderCVLink()}
            {renderEmploymentStatus()}
          </Fragment>
        ),
      });
    }
    return tabs;
  }, [t, user]);

  const contentTabs: TabsProps['items'] = useMemo(() => {
    const tabs: TabsProps['items'] = [];
    if (!userId) return tabs;

    if (isHasAccess(ASSIGNMENTS_TAB_PERMISSIONS)) {
      tabs.push({
        key: 'assignments',
        label: t('UserOverview:UserCard.content.tabs.assignments'),
        children: <InvolvementTimelines userId={userId} />,
      });
    }

    if (isHasAccess(PERMISSION_TAB_PERMISSIONS)) {
      tabs.push({
        key: 'permissions',
        label: t('UserOverview:UserCard.content.tabs.permissions'),
        children: <PermissionsTab userId={userId} />,
      });
    }

    return tabs;
  }, [t, userId]);

  return (
    <article className={styles.userCardWrapper}>
      <header className={styles.userData}>
        {renderImage()}
        <div className={styles.userDataItems}>
          {renderHeading()}
          <Tabs items={headerTabs} defaultActiveKey="basic" className={tabStyles.customTabs} />
        </div>
      </header>
      <section className={styles.content}>
        <Tabs items={contentTabs} className={tabStyles.customTabs} />
      </section>
    </article>
  );
};
