import { useCallback } from 'react';
import { App, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { useCreateAGMutation } from 'entities/accessGroup';

import NameInput from '../ui/NameInput/NameInput';

export const useCreateModal = (): (() => void) => {
  const { modal } = App.useApp();
  const { t } = useTranslation('AccessGroup');

  const { mutateAsync: createAGMutation } = useCreateAGMutation();

  const openCreateModal = useCallback((): void => {
    const instance = modal.confirm({
      title: t('CreateModal.title'),
      transitionName: '',
      centered: true,
      closable: true,
      okButtonProps: { htmlType: 'submit' },
      cancelText: t('RenameModal.cancelButton'),
      okText: t('CreateModal.confirmButton.text'),
      onOk: () => {
        return Promise.reject();
      },
      modalRender: (dom) => (
        <Form
          onFinish={({ name: newName }) => {
            const trimmedName = newName.trim();
            instance.update({
              okButtonProps: { loading: true },
            });
            createAGMutation(trimmedName, {
              onSettled: () => instance.destroy(),
            });
          }}
        >
          {dom}
        </Form>
      ),
      content: <NameInput />,
    });
  }, [createAGMutation, modal, t]);

  return openCreateModal;
};
