import { AxiosRequestConfig } from 'axios';
import { axiosInstance } from 'shared/lib/axios';
import { FilterValuesResponse, PageUserDTO, UserOverviewRequest } from './types';

export const userOverviewListGet = (
  { filtration, size, page, searchQuery }: UserOverviewRequest,
  config?: AxiosRequestConfig,
): Promise<PageUserDTO> => {
  const { orders, filters, availableUsers, showOnlyNonBillable } = filtration;
  return axiosInstance.post(
    `/api/user-overview`,
    {
      orders,
      filters,
      availableUsers,
    },
    { params: { page, size, searchQuery, showOnlyNonBillable, showTerminated: true }, ...config },
  );
};

export const userOverviewFilterListGet = (): Promise<FilterValuesResponse> => {
  return axiosInstance.get('/api/user-overview/filters');
};
