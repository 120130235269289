import { Skeleton, Table } from 'antd';

import styles from './UserTableLoader.module.scss';

const UserTableLoader = ({ columnsLength }: { columnsLength: number }): JSX.Element => {
  return (
    <Table.Summary.Row>
      {Array.from({ length: columnsLength }, (_, index) => (
        <Table.Summary.Cell key={index} index={index}>
          <Skeleton
            paragraph={false}
            avatar={index === 0 ? { size: 22, shape: 'circle' } : false}
            active
            title={{ width: '100%' }}
            className={styles.skeleton}
          />
        </Table.Summary.Cell>
      ))}
    </Table.Summary.Row>
  );
};

export default UserTableLoader;
