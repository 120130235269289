import { UseMutationResult, useMutation, queryOptions, keepPreviousData, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { keys as demandKeys } from 'entities/demand';

import { CommonError } from 'shared/api/types';
import { handleRequestError } from 'shared/lib/axios';
import { queryClient } from 'shared/lib/react-query';
import { pathKeys } from 'shared/lib/react-router';

import { addNotification } from 'shared/model/notification';
import { getLinkButton } from 'shared/ui/LinkButton';

import {
  accountItemArchive,
  accountItemCreate,
  accountItemGet,
  accountItemUpdate,
  accountListGet,
  accountNamesGetByIds,
} from './requests';
import {
  AccountCreateRequest,
  AccountDTO,
  AccountList,
  AccountUpdateRequest,
  AccountsListRequest,
  AccountsNameDTO,
} from './types';

export const keys = {
  root: () => ['account'] as const,
  list: () => [...keys.root(), 'list'] as const,
  listWithFilters: (filters: AccountsListRequest) => [...keys.root(), 'list', filters] as const,
  item: (id: string) => [...keys.root(), 'item', id] as const,
  create: () => [...keys.root(), 'create'] as const,
  update: (id: string) => [...keys.root(), 'update', id] as const,
  archive: (id: string) => [...keys.root(), 'archive', id] as const,
  names: () => [...keys.root(), 'names'] as const,
  namesByIds: (ids: string[]) => [...keys.root(), 'names', ids] as const,
};

export const accountsService = {
  queryKey: (filters: AccountsListRequest) => keys.listWithFilters(filters),

  getCache: (filters: AccountsListRequest) => queryClient.getQueryData<AccountList>(accountsService.queryKey(filters)),

  queryOptions: (filters: AccountsListRequest) => {
    const accountsKey = accountsService.queryKey(filters);
    return queryOptions({
      queryKey: accountsKey,
      queryFn: async ({ signal }) => {
        const accounts = await accountListGet(filters, { signal });
        return accounts;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => accountsService.getCache(filters)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(accountsKey)?.dataUpdatedAt,
      placeholderData: keepPreviousData,
    });
  },

  prefetchQuery: async (filters: AccountsListRequest) =>
    queryClient.prefetchQuery(accountsService.queryOptions(filters)),
};

export const accountService = {
  queryKey: (id: string) => keys.item(id),

  getCache: (id: string) => queryClient.getQueryData<AccountDTO>(accountService.queryKey(id)),

  setCache: (account: AccountDTO) => queryClient.setQueryData(accountService.queryKey(account.id), account),

  removeCache: (id: string) => queryClient.removeQueries({ queryKey: accountService.queryKey(id) }),

  queryOptions: (id: string, enabled = true) => {
    const accountKey = accountService.queryKey(id);
    return queryOptions({
      queryKey: accountKey,
      queryFn: async ({ signal }) => {
        const account = await accountItemGet(id, { signal });
        return account;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => accountService.getCache(id)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(accountKey)?.dataUpdatedAt,
      enabled,
    });
  },

  prefetchQuery: async (id: string) => queryClient.prefetchQuery(accountService.queryOptions(id)),
};

export const useCreateAccountMutation = (): UseMutationResult<AccountDTO, CommonError, AccountCreateRequest> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.create(),
    mutationFn: accountItemCreate,
    onSuccess: (data) => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      accountService.setCache(data);
      addNotification({
        type: 'short',
        method: 'success',
        description: 'Account:AccountsPage.notification.successfullyCreated.description',
        btn: getLinkButton(
          pathKeys.project.root({ accountId: data.id }),
          'Account:AccountsPage.notification.successfullyCreated.button.label',
        ),
      });
      navigate(pathKeys.account.root());
    },
    onError: (error) => {
      if (error.response?.data.code !== 'ItemAlreadyExistsException') {
        handleRequestError(error);
      }
    },
  });
};

export const useUpdateAccountMutation = (
  id: string,
): UseMutationResult<AccountDTO, CommonError, AccountUpdateRequest> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  const updateKey = keys.update(id);

  return useMutation({
    mutationKey: updateKey,
    mutationFn: accountItemUpdate,
    onSuccess: (data) => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: demandKeys.parentInfo() });
      accountService.setCache(data);
      addNotification({
        type: 'short',
        method: 'success',
        description: 'Account:AccountsPage.notification.successfullyUpdated.description',
      });
      navigate(pathKeys.account.root());
    },
    onError: (error) => {
      if (error.response?.data.code !== 'ItemAlreadyExistsException') {
        handleRequestError(error);
      }
    },
  });
};

export const useArchiveAccountMutation = (id: string): UseMutationResult<void, CommonError, string> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  const archiveKey = keys.archive(id);

  return useMutation({
    mutationKey: archiveKey,
    mutationFn: accountItemArchive,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      accountService.removeCache(id);
      addNotification({
        type: 'short',
        method: 'success',
        description: 'Account:AccountsPage.notification.successfullyArchived.description',
      });
      navigate(pathKeys.account.root());
    },
    onError: (error) => {
      if (error.response?.data.code === 'ItemNotFoundException') {
        addNotification({
          type: 'long',
          method: 'error',
          description: 'Account:AccountCreateUpdateFormItems.notification.ItemNotFoundException.description',
        });
        return;
      }
      if (error.response?.data.code !== 'ProjectsHasUnarchivedAssignmentsException') {
        handleRequestError(error);
      }
    },
  });
};

export const accountsNamesService = {
  queryKey: (ids: string[]) => keys.namesByIds(ids),

  getCache: (ids: string[]) => queryClient.getQueryData<AccountsNameDTO[]>(accountsNamesService.queryKey(ids)),

  removeCache: () => queryClient.removeQueries({ queryKey: keys.names() }),

  queryOptions: (ids: string[]) => {
    const accountNamesKey = accountsNamesService.queryKey(ids);
    return queryOptions({
      queryKey: accountNamesKey,
      queryFn: async ({ signal }) => {
        const names = await accountNamesGetByIds(ids, { signal });
        return names;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => accountsNamesService.getCache(ids)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(accountNamesKey)?.dataUpdatedAt,
      enabled: ids.length > 0,
    });
  },
};
