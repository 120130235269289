import React, { ReactNode } from 'react';

import { Maybe } from 'shared/types';
import { Avatar } from 'shared/ui/Avatar';
import { ICustomColumnType } from 'shared/ui/VirtualTable';

import { UserName } from '../UserCard';

import styles from './UserTable.module.scss';

export const PAGE_SIZE = 40;
export const THRESHOLD_VALUE = 0.9;

export const columns: ICustomColumnType<object>[] = [
  {
    title: 'UserOverview:UserTable.header.column.name.title',
    dataIndex: 'name',
    key: 'firstName',
    ellipsis: true,
    sorter: true,
    width: 250,
    render: (name: { id: string; firstName: string; lastName: string; picture: Maybe<string> }): ReactNode => {
      const fullName = `${name.firstName} ${name.lastName}`;

      return (
        <div className={styles.nameCellRootWrapper}>
          <Avatar picture={name.picture} text={fullName} size={22} />
          <UserName name={fullName} id={name.id} />
        </div>
      );
    },
  },
  {
    title: 'UserOverview:UserTable.header.column.seniority.title',
    dataIndex: 'seniority',
    key: 'seniority',
    ellipsis: true,
    sorter: true,
    width: 150,
  },
  {
    title: 'UserOverview:UserTable.header.column.specialization.title',
    dataIndex: 'specialization',
    key: 'specialization',
    ellipsis: true,
    sorter: true,
    width: 150,
  },
  {
    title: 'UserOverview:UserTable.header.column.workProfile.title',
    dataIndex: 'workProfile',
    key: 'workProfile',
    ellipsis: true,
    sorter: true,
    width: 150,
  },
  {
    title: 'UserOverview:UserTable.header.column.manager.title',
    dataIndex: 'managerBamboo',
    key: 'managerBamboo',
    ellipsis: true,
    sorter: true,
    width: 150,
  },
  {
    title: 'UserOverview:UserTable.header.column.email.title',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
    sorter: true,
    width: 150,
  },
  {
    title: 'UserOverview:UserTable.header.column.location.title',
    dataIndex: 'location',
    key: 'location',
    ellipsis: true,
    sorter: true,
    width: 150,
  },
  {
    title: 'UserOverview:UserTable.header.column.employmentType.title',
    dataIndex: 'employmentType',
    key: 'employmentType',
    ellipsis: true,
    sorter: true,
    width: 150,
  },
  {
    title: 'UserOverview:UserTable.header.column.engaged.title',
    dataIndex: 'engaged',
    key: 'engaged',
    ellipsis: true,
    sorter: true,
    width: 150,
    render: (value: boolean): ReactNode => {
      return value ? 'Engaged' : 'Disengaged';
    },
  },
];
